import { Node } from "./api/APITypes"
import { store } from "./state/Store"

export type SortOrderType = 'default' | 'date' | 'relevance'

export default function sortNodes(nodes: Node[], sortOrder: SortOrderType) {
    switch(sortOrder){
        case 'default':
            return nodes
        case 'date':
            return [...nodes].sort((a,b) => new Date(b.properties.judgementdate!).getTime() - new Date(a.properties.judgementdate!).getTime())
        case 'relevance':
            return [...nodes].sort((a,b) => a.properties.importance - b.properties.importance)
    }
}