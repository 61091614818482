import {
  Button,
  Grid,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Spinner,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { CSSProperties, useEffect, useState } from 'react';
import { Search } from 'react-feather';
import theme from '../theme';
import DocumentCard from '../components/DocumentCard';
import { useAIsearchQuery, useSearchQuery } from '../lib/api/GraphAPI';
import sortNodes, { SortOrderType } from '../lib/Sort';
import { setContextSearch, setExactSearch, setSearchQuery } from '../lib/state/slices/ViewSlice';
import { store } from '../lib/state/Store';
import { useAppDispatch, useAppSelector } from '../lib/hooks';

function Header() {
  return (
    <div style={{ width: '100%', padding: '20px' }}>
      <Text fontWeight={700} fontSize={'xx-large'}>
        Case-search
      </Text>
    </div>
  );
}

function Searchbar(props: { style?: CSSProperties }) {
  const [debouncedQuery, setDebouncedQuery] = useState(store.getState().viewReducer.searchQuery);
  const dispatch = useAppDispatch();
  // UseEffect to implement the debouncing
  useEffect(() => {
    const handler = setTimeout(() => {
      dispatch(setSearchQuery(debouncedQuery)); // Update the parent's query state after 5 seconds
    }, 1000); // 5 seconds debounce delay

    // Cleanup function: clears timeout if the component unmounts or the input changes before 5 seconds
    return () => {
      clearTimeout(handler);
    };
  }, [debouncedQuery]); // Only trigger when `debouncedQuery` changes

  return (
    <div style={{ ...props.style }}>
      <InputGroup>
        <InputLeftElement>
          <Search />
        </InputLeftElement>
        <Input
          onChange={(e) => setDebouncedQuery(e.target.value)}
          placeholder="Search for a case..."
          width={'536px'}
          backgroundColor={'white'}
          value={debouncedQuery}
        />
      </InputGroup>
    </div>
  );
}

function Searchtype(props: {
  style?: CSSProperties;
  searchMode: 'exact' | 'context';
  setSearchMode: (searchMode: 'exact' | 'context') => void;
}) {
  return (
    <div
      style={{
        ...props.style,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Text fontWeight={500} fontSize={'small'}>
        Search type:
      </Text>
      <Button
        variant={'solid'}
        colorScheme={props.searchMode === 'exact' ? 'purple' : 'gray'}
        marginLeft={'10px'}
        size={'sm'}
        onClick={() => props.setSearchMode('exact')}
      >
        Exact match
      </Button>
      <Button
        size={'sm'}
        colorScheme={props.searchMode === 'context' ? 'purple' : 'gray'}
        onClick={() => props.setSearchMode('context')}
      >
        Contextual
      </Button>
    </div>
  );
}

function SortDropdown(props: {
  sortOrder: 'default' | 'date' | 'relevance';
  setSortOrder: (sortOrder: 'default' | 'date' | 'relevance') => void;
}) {
  return (
    <div>
      <Select
        value={props.sortOrder}
        onChange={(e) => props.setSortOrder(e.target.value as 'default' | 'date' | 'relevance')}
        style={{ padding: '8px', borderRadius: '4px' }}
        width="200px"
      >
        <option value="default">Best Match</option>
        <option value="date">Sort by date (new-old)</option>
        <option value="relevance">Sort by importance</option>
      </Select>
    </div>
  );
}

function AISearchResults() {
  const { data, isLoading, isSuccess } = useAIsearchQuery(store.getState().viewReducer.searchQuery);
  const dispatch = useAppDispatch();

  if (isSuccess && data) {
    dispatch(setContextSearch(data.nodes));
    return null;
  }
  return null;
}

function SearchResults() {
  const { data, isLoading, isSuccess } = useSearchQuery(store.getState().viewReducer.searchQuery);
  const dispatch = useAppDispatch();

  if (isSuccess && data) {
    dispatch(setExactSearch(data));
    return null;
  }

  return null;
}

export default function SearchPage() {
  const [searchMode, setSearchMode] = useState<'exact' | 'context'>('exact'); //default to exact
  const [sortOrder, setSortOrder] = useState<'default' | 'date' | 'relevance'>('default'); //default to relevance
  let exactSearchResults = useAppSelector((state) => state.viewReducer.exactSearch);
  let contextSearchResults = useAppSelector((state) => state.viewReducer.contextualSearch);
  let query = useAppSelector((state) => state.viewReducer.searchQuery);
  let nodes = searchMode === 'exact' ? exactSearchResults : contextSearchResults;
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        overflow: 'hidden',
      }}
    >
      <Header />
      <Stack direction="row" spacing={4} padding={'10px'}>
        <Searchbar />
        <Searchtype searchMode={searchMode} setSearchMode={setSearchMode} />
        <SortDropdown sortOrder={sortOrder} setSortOrder={setSortOrder} />
      </Stack>
      {store.getState().viewReducer.searchQuery !== '' ? (
        searchMode === 'context' ? (
          <AISearchResults key={query}/>
        ) : (
          <SearchResults key={query}/>
        )
      ) : null}
      <Grid
        style={{
          height: '100%',
          backgroundColor: theme.colors.gray[100],
          borderRadius: '10px',
          padding: '10px',
          overflowY: 'scroll',
          scrollbarWidth: 'thin',
          justifyItems: 'center',
        }}
        templateColumns={'repeat(auto-fit, minmax(380px, 1fr))'}
        gap={4}
      >
        {sortNodes(nodes, sortOrder).map((node) => (
          <DocumentCard key={node.properties.id} id={node.properties.id} />
        ))}
      </Grid>
    </div>
  );
}
