import { useAuth } from 'oidc-react';
import { useGetUserStateQuery } from './api/GraphAPI';
import { setView } from './state/slices/ViewSlice';
import { store } from './state/Store';
import { useEffect } from 'react';

export default function LoadAppStateFromServer() {
  const { data, error, isLoading, isSuccess, refetch } = useGetUserStateQuery();
  const auth = useAuth();
  if (isSuccess && data) {
    store.dispatch(setView(data[0].json_state));
  }
  useEffect(() => {
    refetch();
  }, [auth.userData?.profile.sub]);
  return null;
}
