import { useSigma } from '@react-sigma/core';
import DocumentCard from '../../components/DocumentCard';
import { useEffect, useState } from 'react';

export default function FloatingDocument(props: {
  elementId: string;
  id: string;
  onClose: () => void;
}) {
  const [nodeInitialized, setNodeInitialized] = useState(false);
  let sigma = useSigma();
  const [position, setPosition] = useState(() => {
    try {
      let attributes = sigma.getGraph().getNodeAttributes(props.elementId);
      let viewportPosition = sigma.graphToViewport({ x: attributes.x, y: attributes.y });
      viewportPosition.x = viewportPosition.x - 200;
      viewportPosition.y = viewportPosition.y + 10;
      setNodeInitialized(true);
      return viewportPosition;
    } catch (e) {
      return { x: 0, y: 0 };
    }
  });
  useEffect(() => {
    sigma.on('afterRender', () => {
      try {
        let attributes = sigma.getGraph().getNodeAttributes(props.elementId);
        let viewportPosition = sigma.graphToViewport({ x: attributes.x, y: attributes.y });
        viewportPosition.x = viewportPosition.x - 200;
        viewportPosition.y = viewportPosition.y + 10;
        setNodeInitialized(true);

        setPosition(viewportPosition);
      } catch (e) {}
    });
  }, [props.elementId]);

  if (!nodeInitialized) {
    return null;
  } else {
    return (
      <DocumentCard
        style={{ position: 'absolute', top: position.y, left: position.x }}
        id={props.id}
        onClose={props.onClose}
      />
    );
  }
}
