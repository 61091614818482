import {
  Card,
  CardBody,
  CardFooter,
  Text,
  OrderedList,
  ListItem,
  Box,
  Accordion,
  AccordionItem,
  AccordionPanel,
  AccordionButton,
  AccordionIcon,
  AspectRatio,
  Heading,
} from '@chakra-ui/react';

type Example = {
  //lowest level box to make the examples within the card
  heading: string;
  description: string;
  videoUrl?: string;
};

type GuideCard = {
  //middle box that has examples in it
  heading: string;
  description: string;
  examples: Example[];
};

type GuideStep = {
  //outer box that has cards in it
  heading: string;
  description: string;
  guideCards: GuideCard[];
};

const listOfGuideSteps: GuideStep[] = [
  {
    heading: 'Find a starting case',
    description:
      'Begin your research by locating a relevant starting case. This could be a case you’re familiar with, or one suggested by the tool’s recommendation engine.',
    guideCards: [
      {
        heading: 'Browsing Suggested Cases',
        description:
          'You can choose a case from the ones recommended by the Council of Europe. These can be found on the front page or by clicking recommended cases, selecting a right from the ECHR, and choosing a keyword within this topic. You can also use filters to select country, importance level and sort by date.',
        examples: [
          {
            heading: 'Quick example from the front page',
            description:
              'In the video below we show how you can find a case from the front page, expand it to read more and then open it.',
            videoUrl: '1.open_case_in_graph.mkv',
          },
          //  { heading: 'Full example with filtering', description: 'asnf' },
        ],
      },
      {
        heading: 'Using Keywords or Context to Search',
        description:
          'Enter keywords or full sentences related to your research topic to find a case that best matches your interests. You can switch between search types by clicking the button forkeywords or context. The tool will then display a list of related cases.',
        examples: [
          {
            heading: 'Example of a keyword search',
            description:
              'In the video below we show how you can search for specific words and get a list of related cases.',
            videoUrl: '2.search.mkv',
          },
          {
            heading: 'Example of a contextual search',
            description:
              'In the video below we show how our tool can find a case that best matches your search.',
            videoUrl: 'contextual_search.mp4',
          },
        ],
      },
    ],
  },
  {
    heading: 'Open the Case in the Graph Viewer',
    description:
      "Once you've found a relevant case, open it in the graph viewer to explore the connections and relationships between it and other cases.",
    guideCards: [
      {
        heading: 'Navigating the Graph Viewer',
        description:
          'Use the navigation tools to zoom in and out, or pan across the graph to explore connected cases.',
        examples: [
          {
            heading: 'Example of navigation in the graph',
            description:
              'In the video below we show how you can navigate the graph. You can hover over cases to see their names and their connections, click on them to view detailed information about that case, including its related keywords, case facts, and any linked resources.',
            videoUrl: '3.see_connections.mkv',
          },
          {
            heading: 'Example of using the timeline',
            description:
              'The video below shows how you can use the timeline to see the when the judgements of each case were made. You can then drag or click the slider to only see cases within a specific time period.',
            videoUrl: 'using_timeline.mp4',
          },
        ],
      },
      {
        heading: 'Filter through the cases',
        description:
          'Use the filter function to remove less important cases, or to only view cases with a specific feature.',
        examples: [
          {
            heading: 'Example with quick filtering',
            description:
              'The video below shows how you can filter out specific cases easily by using the quick filters on the right. For example to remove all level 2 cases that do not have violations.',
            videoUrl: 'quick_filtering.mp4',
          },
          {
            heading: 'Example with advanced filtering',
            description:
              'The video below shows how you you can filter out cases from specific countries, desicion levels and importances.',
            videoUrl: 'advanced_filtering.mp4',
          },
        ],
      },
    ],
  },
  {
    heading: 'Research further into the case-connections',
    description:
      'Investigate how cases are related by following the connections in the graph viewer. This step helps you understand the broader context or network around your selected case.',
    guideCards: [
      {
        heading: 'Discovering Indirect Relationships',
        description:
          'Look for more distant connections by expanding the graph with new related cases that may highlight less obvious, yet important, relationships between cases. These features can be found by right-clicking on a case in the graph.',
        examples: [
          {
            heading: 'Example of expanding to see second degree cases',
            description:
              'The video below shows how you can expand the graph to see second degree cases.',
            videoUrl: 'expanding_neighbours.mp4',
          },
          {
            heading: 'Example on how to get case recommendations',
            description:
              'The video below shows how you can get recommended cases (similar content) for your selected case, shown in orange in the graph. Note that these are not direct citations, but are generated by AI.',
            videoUrl: 'recommend_neighbours.mp4',
          },
        ],
      },
    ],
  },
  {
    heading: 'Save Interesting Cases to View Later',
    description:
      'Mark cases that are of particular interest and save them for later review or to revisit after gathering more information.',
    guideCards: [
      {
        heading: 'Adding to Your Saved Cases List',
        description:
          "Right click on a case to get the options menu. Here you can click the 'Save case' button to add a case to one of your folders for easy access in the future.",
        examples: [
          {
            heading: 'Example of saving and removing a case from the graph',
            description:
              'The video below shows how you can save a case from the graph in a list, and then remove the case from your saved cases list.',
            videoUrl: 'saving_cases.mp4',
          },
        ],
      },
      // {
      //   heading: 'Organizing Saved Cases',
      //   description:
      //     'Create folders to organize saved cases by topic, importance, or research stage. Move the cases between folders by assigning it with the button.',
      //   examples: [
      //     { heading: 'Example of making a folder', description: 'description' },
      //     { heading: 'Example of moving a case', description: 'description' },
      //   ],
      // },
    ],
  },
  // {
  //   heading: 'Compare the Cases',
  //   description:
  //     'After exploring the connections, compare the cases to identify patterns, differences, or trends that may support your research goals.',
  //   guideCards: [
  //     {
  //       heading: 'Side-by-Side Comparison',
  //       description:
  //         'Open several cases within the graph to view two or more cases at once, examining their key attributes and connections.',
  //       examples: [{ heading: 'Example of side by side comparison', description: 'description' }],
  //     },
  //     {
  //       heading: 'Saved Cases vs Graph Cases',
  //       description:
  //         'Open a case in the graph and view one of your previously saved cases to compare them side by side.',
  //       examples: [
  //         { heading: 'Example of comparison from saved cases', description: 'description' },
  //       ],
  //     },
  //   ],
  // },
];

function GuideCard(props: { cardHeading: string; cardDescription: string; examples: Example[] }) {
  return (
    <Card margin="12px">
      <CardBody>
        <Heading size="md" paddingBottom="12px">
          {props.cardHeading} {/* Main step heading */}
        </Heading>
        <Text>{props.cardDescription}</Text>
      </CardBody>

      <CardFooter>
        <Accordion allowMultiple width="100%">
          {props.examples.map((example, index) => (
            <AccordionItem key={index}>
              <h2>
                <AccordionButton>
                  <Box as="span" flex="1" textAlign="left">
                    <Text fontWeight="bold">{example.heading}</Text> {/* Example heading */}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Text paddingBottom={'12px'}>{example.description}</Text>
                {example.videoUrl && (
                  <AspectRatio>
                    <video
                      controls
                      controlsList="nodownload"
                      src={example.videoUrl}
                      style={{
                        width: 'auto',
                        height: 'auto',
                      }}
                    />
                  </AspectRatio>
                )}
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </CardFooter>
    </Card>
  );
}

function GuideStep({ heading, description, guideCards }: GuideStep) {
  return (
    <Box marginBottom="24px" maxWidth={'800px'}>
      <Heading size="md" paddingBottom="12px">
        {heading}
      </Heading>
      <Text paddingBottom="12px">{description}</Text>
      {guideCards.map((card, index) => (
        <GuideCard
          key={index}
          cardHeading={card.heading}
          cardDescription={card.description}
          examples={card.examples}
        />
      ))}
    </Box>
  );
}

export default function GuidePage() {
  const guideSteps = { listOfGuideSteps };
  return (
    <Box
      style={{
        height: '100%',
        width: '100%',
        margin: '20px',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        padding: '24px',
        overflowY: 'auto',
      }}
    >
      <Heading size="lg" paddingBottom="12px">
        How to use MESHR
      </Heading>
      <Text paddingBottom="12px" marginBottom="20px">
        A quick user guide with helpful hints on how to get started with your research.
      </Text>
      <Heading size="md" paddingBottom="12px">
        Overview of colors and sizes
      </Heading>
      <Text paddingBottom="12px" marginBottom="20px">
        Colors inside the case-node indicate how <strong>high the importance</strong> of the case
        is. The darker the color, the more important the case is.
      </Text>
      <Text paddingBottom="12px" marginBottom="20px">
        The borders around the case-node indicate what the result of the case was.{' '}
        <strong style={{ color: 'red' }}>Red</strong> for "there has been found a violation",{' '}
        <strong style={{ color: 'green' }}>green</strong> for "there has been found a
        non-violation", <strong style={{ color: 'orange' }}>orange</strong> for both violation and
        non-violation, and <strong style={{ color: 'blue' }}>blue</strong> for "there has neither
        been found a violation, or a non-violation".
      </Text>
      <Text paddingBottom="12px" marginBottom="20px">
        The sizes of the case-nodes indicate the <strong>amount of citations</strong> a case has.
        The larger the node, the more connections it has.
      </Text>

      <OrderedList>
        {listOfGuideSteps.map((step, index) => (
          <ListItem paddingBottom="16px">
            <GuideStep
              key={index}
              heading={step.heading}
              description={step.description}
              guideCards={step.guideCards}
            />
          </ListItem>
        ))}
      </OrderedList>
    </Box>
  );
}
