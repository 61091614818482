import { useState } from 'react';
import { useAppSelector } from './hooks';
import { useSaveUserStateMutation } from './api/GraphAPI';

export default function AutoSaver() {
  const state = useAppSelector((state) => state.viewReducer);
  const [oldState, setOldState] = useState(state);
  const [newState, setNewState] = useState(state);
  const [saveView] = useSaveUserStateMutation();


  setTimeout(() => {
    if (newState !== state) {
        setNewState(state);
    }
  }, 5000);

  if (oldState !== newState) {
    setOldState(newState);
    saveView(state);
  } else {
    return null;
  }
  return null
}
