import './App.css';
import { AuthProvider, useAuth } from 'oidc-react';
import { store } from './lib/state/Store';
import { Provider } from 'react-redux';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme';
import { token } from './lib/state/slices/AuthSlice';
import PageWithSidebar from './layouts/SidebarLayout';
import ResearchPage from './pages/ResearchPage';
import SearchPage from './pages/SearchPage';
import GraphPage from './pages/GraphOverviewPage';
import AuthHandler from './lib/AuthHandler';
import GuidePage from './pages/GuidePage';
import LoadingScreen from './pages/LoadingScreen';
import SavedCasesPage from './pages/SavedCasesPage';
import AutoSaver from './lib/AutoSaver';
import LoadAppStateFromServer from './lib/LoadAppStateFromServer';

function App() {
  return (
    <div className="App">
      <ChakraProvider theme={theme}>
        <AuthProvider
          authority={
            'https://auth.meshr.org/realms/' +
            (process.env.REACT_APP_AUTH_REALM
              ? process.env.REACT_APP_AUTH_REALM
              : 'no auth realm set')
          }
          clientId="frontend"
          redirectUri={
            process.env.REACT_APP_REDIRECT_URL
              ? process.env.REACT_APP_REDIRECT_URL
              : 'http://localhost:3000'
          }
          onSignIn={(user) => {
            // the `user` prop is actually the data the app received from `/userinfo` endpoint.
            store.dispatch(token(user!!.access_token));
          }}
          autoSignIn={true}
          automaticSilentRenew={true}
        >
          <AuthHandler>
            <Provider store={store}>
              <LoadAppStateFromServer />
              <AutoSaver />
              <BrowserRouter>
                <PageWithSidebar>
                  <Routes>
                    <Route path="/" element={<Navigate to={'/research'} />} />
                    <Route path="/research" element={<ResearchPage />} />
                    <Route path="/search" element={<SearchPage />} />
                    <Route path="/recommended_cases" element={<ResearchPage />} />
                    <Route path="/saved_cases" element={<SavedCasesPage />} />
                    <Route path="/graph/*" element={<GraphPage />} />
                    <Route path="/learn_more/*" element={<GuidePage />} />
                    <Route path="/loading" element={<LoadingScreen />} />
                  </Routes>
                </PageWithSidebar>
              </BrowserRouter>
            </Provider>
          </AuthHandler>
        </AuthProvider>
      </ChakraProvider>
    </div>
  );
}

export default App;
