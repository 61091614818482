import { useAuth } from 'oidc-react';
import { store } from './state/Store';
import { token } from './state/slices/AuthSlice';
import SplashScreen from '../pages/SplashScreen';

export default function AuthHandler(props: { children: React.ReactNode }) {
  const auth = useAuth();
  console.log(auth.userData);
  if (auth.userData) {
    store.dispatch(token(auth.userData!!.access_token));
  } else if (!auth.userData && !auth.isLoading) {
    auth.signIn();
  }

  return <>{auth.isLoading || !auth.userData ? <SplashScreen /> : props.children}</>;
}
