import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
  name: 'auth',
  initialState: { accesstoken: '' },
  reducers: {
    token: (state, action: { payload: string }) => {
      state.accesstoken = action.payload;
    },
  },
});

export const { token } = authSlice.actions;

export default authSlice.reducer;
