export default function SplashScreen() {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <img src="/logo512.png" alt="logo" style={{ height: '100px', width: '100px' }} />
    </div>
  );
}
