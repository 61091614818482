import { createSlice } from '@reduxjs/toolkit';

export const quackSlice = createSlice({
  name: 'quack',
  initialState: { quack: false },
  reducers: {
    addQuack: (state, action: { payload: boolean }) => {
      state.quack = action.payload;
    },
  },
});

export const { addQuack } = quackSlice.actions;

export default quackSlice.reducer;
