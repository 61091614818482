import { ReactElement } from 'react';
import Sidebar from '../components/Sidebar';
import SavedDocumentsList from '../components/SavedDocumentsList/SavedDocumentsList';

export default function PageWithSidebar(props: { children: ReactElement }) {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', height: '100%', width: '100%', overflow: 'hidden' }}>
      <Sidebar />
      {props.children}
      <SavedDocumentsList />
    </div>
  );
}
